"use client";
import React from 'react';

interface FluidButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const FluidButton = ({ onClick, children, onMouseEnter, onMouseLeave }: FluidButtonProps) => {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="relative px-6 py-2 rounded-lg text-sm font-bold overflow-hidden group whitespace-nowrap"
    >
      {/* Gradient background - using Tailwind for positioning, CSS for animation */}
      <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
        <div className="absolute inset-0 gradient-animate" />
      </div>
      
      {/* Button text - using Tailwind */}
      <span className="relative z-10 text-black group-hover:text-black transition-colors duration-200">
        {children}
      </span>

      <style jsx>{`
        button {
          background: white;
        }
        
        .gradient-animate {
          background: linear-gradient(90deg, #75A5FF, #FF84B1, #FFCE80);
          background-size: 300% 300%;
          animation: gradientFlow 0.6s ease forwards;
        }

        @keyframes gradientFlow {
          0% { 
            background-position: 0% 50%;
          }
          100% { 
            background-position: 100% 50%;
          }
        }
      `}</style>
    </button>
  );
};

export default FluidButton;