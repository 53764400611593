import React from 'react';
import { SignedIn, SignedOut, UserButton, SignInButton } from '@clerk/nextjs';
import HighlightForm from './HighlightForm';
import ResponsiveGradientHeading from './ResponsiveGradientHeading';

const Hero = () => {
  return (
    <section className="relative py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 text-center md:text-left md:pl-2 lg:pl-4">
            <ResponsiveGradientHeading />
            <p className="text-2xl font-semibold text-white mb-2">
              Convert 3 streams on us. Free.
            </p>
            <HighlightForm />
            <p className="text-lg text-white">
              No credit card. No watermark.
            </p>
          </div>
          <div className="md:w-1/2 mt-8 md:mt-0">
            <img src="https://i.ibb.co/8DhRgL2/Group-5457.png" alt="Hero Image" className="mx-auto md:mx-0 rounded-lg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;