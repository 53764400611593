// ErrorBox.tsx
import React from 'react';
import { Snackbar, Alert, Slide, SlideProps } from '@mui/material';

interface ErrorBoxProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="left" />;
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ open, message, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert 
        onClose={onClose} 
        severity="error" 
        sx={{ 
          width: '100%',
          bgcolor: 'rgba(211, 47, 47, 0.9)',
          color: '#fff',
          '& .MuiAlert-icon': {
            color: '#fff'
          }
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default ErrorBox;
