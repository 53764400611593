"use client";

import React, { useEffect, useState } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { Link as ScrollLink } from 'react-scroll';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import Link from 'next/link';
import Image from 'next/image';
import FluidButton from './FluidButton';

const Navbar = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [isHomePage, setIsHomePage] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    setIsMounted(true);
    setIsHomePage(pathname === '/');
  }, [pathname]);

  const handleRedirect = (isSignIn: boolean = false) => {
    router.push(isSignIn ? '/sign-in' : '/streams');
    setIsMobileMenuOpen(false);
  };

  const handleNavigation = (sectionId: string) => {
    if (!isHomePage) {
      router.push('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
    setIsMobileMenuOpen(false);
  };

  const NavItem = ({ to, children }) => {
    if (!isMounted) return null;

    if (isHomePage) {
      return (
        <ScrollLink
          to={to}
          smooth={true}
          duration={500}
          className="text-gray-300 hover:text-white transition-colors duration-200 text-sm font-bold cursor-pointer"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          {children}
        </ScrollLink>
      );
    }
    return (
      <button
        onClick={() => handleNavigation(to)}
        className="text-gray-300 hover:text-white transition-colors duration-200 text-sm font-bold whitespace-nowrap"
      >
        {children}
      </button>
    );
  };

  if (!isMounted) return null;

  const navigationItems = (isMobile) => (
    <>
      <div
        className={`${
          isMobile
            ? 'flex flex-col space-y-4 whitespace-nowrap'
            : 'hidden lg:flex items-center space-x-8 whitespace-nowrap'
        }`}
      >
        <div className="ml-4">
          <NavItem to="how-it-works">How It Works</NavItem>
        </div>
        <NavItem to="features">Features</NavItem>
        <div className={`relative ${isMobile ? 'w-full' : 'group'}`}>
          <div
            className="group"
            onMouseEnter={() => !isMobile && setIsResourcesOpen(true)}
            onMouseLeave={() => !isMobile && setIsResourcesOpen(false)}
          >
            <button
              className="text-gray-300 hover:text-white transition-colors duration-200 text-sm font-bold flex items-center whitespace-nowrap"
              onClick={() => isMobile && setIsResourcesOpen(!isResourcesOpen)}
            >
              Resources
              <svg
                className={`ml-1 h-4 w-4 transition-transform duration-200 ${
                  isResourcesOpen ? 'rotate-180' : ''
                }`}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
            <div
              className={`absolute left-0 w-48 rounded-md shadow-lg bg-[#0B0B0C] ring-1 ring-black ring-opacity-5 transition-all duration-200 ease-in-out transform
                ${
                  isResourcesOpen
                    ? 'opacity-100 translate-y-0'
                    : 'opacity-0 translate-y-2 pointer-events-none'
                }`}
            >
              <div className="py-1">
                <Link
                  href="/faq"
                  className="block px-4 py-2 text-sm font-bold text-gray-300 hover:text-white hover:bg-[#1a1a1c] transition-colors duration-200"
                  onClick={() => isMobile && setIsMobileMenuOpen(false)}
                >
                  FAQ
                </Link>
                <a
                  href="https://discord.gg/rrk5NkNegK"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block px-4 py-2 text-sm font-bold text-gray-300 hover:text-white hover:bg-[#1a1a1c] transition-colors duration-200"
                  onClick={() => isMobile && setIsMobileMenuOpen(false)}
                >
                  Discord
                </a>
              </div>
            </div>
          </div>
        </div>
        <Link
          href="/pricing"
          className="text-gray-300 hover:text-white transition-colors duration-200 text-sm font-bold whitespace-nowrap pr-6"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          Pricing
        </Link>
      </div>
    </>
  );

  return (
    <header className="bg-[#0B0B0C] shadow-md sticky top-0 z-50">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center h-16">
          {/* Logo Section */}
          <div className="flex items-center flex-shrink-0">
            <Link href="/">
              <div className="relative h-8 w-auto">
                <Image
                  src="https://storage.googleapis.com/saved_media_assets/SavedLogo.png"
                  alt="Saved Logo"
                  width={128}
                  height={32}
                  priority
                  className="h-8 w-auto"
                />
              </div>
            </Link>
          </div>

          {/* Desktop Navigation */}
          {navigationItems(false)}

          {/* Desktop Buttons */}
          <div className="hidden lg:flex items-center space-x-6">
            <a
              href="https://calendly.com/aayushgupta-startupshell/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-white transition-colors duration-200 text-sm font-bold whitespace-nowrap"
            >
              Talk to Us
            </a>
            <SignedOut>
              <Link href="/sign-in">
                <FluidButton onClick={() => handleRedirect(true)}>Sign In</FluidButton>
              </Link>
            </SignedOut>
            <SignedIn>
              <FluidButton onClick={() => handleRedirect(false)}>Dashboard</FluidButton>
            </SignedIn>
          </div>

          {/* Mobile menu button */}
          <button
            className="lg:hidden text-gray-300 hover:text-white focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle menu"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`lg:hidden transition-all duration-300 ease-in-out ${
          isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
        }`}
      >
        <div className="px-4 pt-2 pb-4 space-y-4 bg-[#0B0B0C] border-t border-gray-800">
          {navigationItems(true)}

          {/* Mobile Auth Buttons */}
          <div className="pt-4 border-t border-gray-800">
            <SignedOut>
              <Link href="/sign-in" onClick={() => setIsMobileMenuOpen(false)}>
                <button className="w-full bg-white hover:bg-gray-100 text-black px-6 py-2 rounded-lg text-sm font-bold transition-colors duration-200">
                  Sign In
                </button>
              </Link>
            </SignedOut>
            <SignedIn>
              <FluidButton onClick={handleRedirect}>Dashboard</FluidButton>
            </SignedIn>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;