import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useAuth } from '@clerk/nextjs';
import { processFrame, getUserSubscriptionInfo } from '../api/process';
import PopupForm from './PopupForm';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/theme';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ErrorBox from './ErrorBox';

interface HighlightData {
  link: string;
  faceBox: number[] | null;
  gameplayBox: number[] | null;
  isFacecam: boolean;
  isFullscreen: boolean;
  videoType: string;
  imageData: any;
  textColor: string;
  censorSubtitles: boolean;
  fontName: string;
}

interface SubscriptionInfo {
  is_paying: boolean;
  free_credits_remaining: number;
}

const normalizeUrl = (link: string): string => {
  let normalizedLink = link;
  
  // Normalize Twitch URLs
  if (normalizedLink.startsWith('https://m.twitch.tv/') || normalizedLink.startsWith('http://m.twitch.tv/')) {
    normalizedLink = normalizedLink.replace('://m.twitch.tv/', '://www.twitch.tv/');
  }
  
  // Normalize YouTube URLs
  if (normalizedLink.includes('youtube.com/')) {
    // Convert mobile to desktop domain
    normalizedLink = normalizedLink.replace('://m.youtube.com/', '://www.youtube.com/');
    
    // Extract video ID and rebuild URL to ensure consistent format
    const videoIdMatch = normalizedLink.match(/[?&]v=([a-zA-Z0-9_-]{11})/);
    if (videoIdMatch) {
      normalizedLink = `https://www.youtube.com/watch?v=${videoIdMatch[1]}`;
    }
  }
  
  return normalizedLink;
};

const HighlightForm: React.FC = () => {
  const [highlightData, setHighlightData] = useState<HighlightData>({
    link: '',
    faceBox: null,
    gameplayBox: null,
    isFacecam: false,
    isFullscreen: true,
    videoType: 'vod',
    imageData: null,
    textColor: 'yellow',
    censorSubtitles: true,
    fontName: "CheGuevaraBarry-Brown",
  });
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const router = useRouter();
  const { isSignedIn } = useAuth();
  const [isLandingPage, setIsLandingPage] = useState<boolean>(false);
  const [currentPath, setCurrentPath] = useState<string>('/');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showError, setShowError] = useState(false);
  const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfo | null>(null);

  useEffect(() => {
    const path = window.location.pathname;
    setIsLandingPage(path === '/');
    setCurrentPath(path);
    console.log('Current path:', path);
  }, []);

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      if (isSignedIn) {
        try {
          const data = await getUserSubscriptionInfo();
          setSubscriptionInfo(data);
        } catch (error) {
          console.error('Error fetching subscription info:', error);
        }
      }
    };

    fetchSubscriptionInfo();
  }, [isSignedIn]);

  const handleGenerate = async () => {
    console.log("URL in handleGenerate:", highlightData.link);

    // Check subscription status for non-paying users
    if (subscriptionInfo && !subscriptionInfo.is_paying && subscriptionInfo.free_credits_remaining === 0) {
      setErrorMessage("No credits remaining, please upgrade your account!");
      setShowError(true);
      return;
    }
  
    const isValidLink = (link: string): 'vod' | 'clip' | null => {
      // Twitch VOD regex
      const twitchVodRegex = /^https?:\/\/(?:www\.)?twitch\.tv\/videos\/(\d+)(?:\?.*)?$/;
      
      // Twitch clip regex
      const twitchClipRegex = /^https?:\/\/(?:www\.)?twitch\.tv\/\w+\/clip\/([A-Za-z0-9_-]+)(?:\?.*)?$/;
      
      // YouTube video regex - matches standard, short, and shared URLs
      const youtubeRegex = /^https?:\/\/(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/|youtube\.com\/shorts\/)([a-zA-Z0-9_-]{11})(?:\?.*)?$/;
    
      // For /streams page - accept YouTube videos and Twitch VODs
      if (currentPath === '/' || currentPath === '/streams') {
        if (twitchVodRegex.test(link) || youtubeRegex.test(link)) {
          return 'vod';
        }
        return null;
      }
      
      // For /clips page - accept only Twitch clips
      if (currentPath === '/clips') {
        if (twitchClipRegex.test(link)) {
          return 'clip';
        }
        return null;
      }
    
      return null;
    };
  
    const linkType = isValidLink(highlightData.link);
    
    if (!linkType) {
      const errorMsg = currentPath === '/clips' 
        ? 'Please enter a valid Twitch clip link'
        : 'Please enter a valid YouTube or Twitch VOD link';
      setErrorMessage(errorMsg);
      setShowError(true);
      return;
    }
  
    setHighlightData(prev => ({ ...prev, videoType: linkType }));
    setIsPopupVisible(true);

    try {
      const frameData = await processFrame(highlightData.link, linkType);

      if (frameData.result) {
        setHighlightData(prev => ({
          ...prev,
          imageData: frameData.result,
          isFacecam: frameData.result.face_coordinates !== null,
        }));
      }
    } catch (error) {
      console.error('Error generating frame:', error);
      setIsPopupVisible(false);
    }
  };

  const handleFaceBoxChange = (newBox: number[]) => {
    setHighlightData(prev => ({ ...prev, faceBox: newBox }));
  };

  const handleGameplayBoxChange = (newBox: number[]) => {
    setHighlightData(prev => ({ ...prev, gameplayBox: newBox }));
  };

  const handleFacecamToggle = (newFacecamState: boolean) => {
    setHighlightData(prev => ({
      ...prev,
      isFacecam: newFacecamState,
      isFullscreen: !newFacecamState,
    }));
  };

  const handleSubmit = (
    faceBox: number[],
    gameplayBox: number[],
    isFacecam: boolean,
    textColor: string,
    censorSubtitles: boolean,
    fontName: string
  ) => {
    if (isLandingPage && !isSignedIn) {
      localStorage.setItem('pendingHighlight', JSON.stringify({
        link: highlightData.link,
        faceBox,
        gameplayBox,
        isFacecam,
        videoType: highlightData.videoType,
        imageData: highlightData.imageData,
        isFullscreen: highlightData.isFullscreen,
        numOfHighlights: 20,
        textColor,
        censorSubtitles,
        fontName
      }));
      
      router.push('/sign-in');
      return;
    }

    submitHighlight(
      highlightData.link,
      faceBox,
      gameplayBox,
      isFacecam,
      textColor,
      censorSubtitles,
      fontName
    );
  };

  const submitHighlight = (
    link: string,
    faceBox: number[],
    gameplayBox: number[],
    isFacecam: boolean,
    textColor: string,
    censorSubtitles: boolean,
    fontName: string
  ) => {
    console.log("URL in submitHighlight:", link);
    const faceCoordinates = faceBox ? JSON.stringify(faceBox.map(Math.floor)) : null;
    const gameplayCoordinates = gameplayBox ? JSON.stringify(gameplayBox.map(Math.floor)) : null;
    const frameUrl = highlightData.imageData?.frame;
    const numOfHighlights = 20; 

    let query = `link=${encodeURIComponent(link)}&isFacecam=${isFacecam}&faceCoordinates=${encodeURIComponent(faceCoordinates)}&gameplayCoordinates=${encodeURIComponent(gameplayCoordinates)}&videoType=${encodeURIComponent(highlightData.videoType)}&textColor=${encodeURIComponent(textColor)}&censorSubtitles=${censorSubtitles}&fontName=${encodeURIComponent(fontName)}`;

    if (highlightData.videoType === 'vod') {
      query += `&numOfHighlights=${numOfHighlights}&frameUrl=${encodeURIComponent(frameUrl || '')}`;
    }

    router.push(`/loading?${query}`);
  };

  useEffect(() => {
    if (isSignedIn) {
      const pendingHighlight = localStorage.getItem('pendingHighlight');
      if (pendingHighlight) {
        const parsedData = JSON.parse(pendingHighlight);
        console.log("Retrieved from localStorage in HighlightForm:", parsedData.link);
        const {
          link,
          faceBox,
          gameplayBox,
          isFacecam,
          videoType,
          imageData,
          isFullscreen,
          numOfHighlights,
          textColor,
          censorSubtitles,
          fontName
        } = parsedData;

        setHighlightData({
          link,
          faceBox,
          gameplayBox,
          isFacecam,
          isFullscreen,
          videoType,
          imageData,
          textColor,
          censorSubtitles,
          fontName
        });

        localStorage.removeItem('pendingHighlight');
        
        submitHighlight(link, faceBox, gameplayBox, isFacecam, textColor, censorSubtitles, fontName);
      }
    }
  }, [isSignedIn, router]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '90%',
        }}
      >
        <TextField
          label={
            currentPath === '/clips'
              ? 'Please enter a valid Twitch clip link.'
              : 'Please enter a YouTube OR Twitch VOD link.'
          }
          variant="outlined"
          fullWidth
          value={highlightData.link}
          onChange={(e) => {
            const normalizedLink = normalizeUrl(e.target.value);
            setHighlightData(prev => ({ ...prev, link: normalizedLink }));
          }}
          type="url"
          required
          sx={{
            flexGrow: 1,
            mr: 1.5,
            '& .MuiInputBase-root': {
              height: '56px',
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiInputBase-input': {
              color: '#ffffff',
              height: '100%',
              padding: '0 14px',
              boxSizing: 'border-box',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.23)',
              },
              '&:hover fieldset': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#ffffff',
              },
            },
            '& .MuiInputLabel-root': {
              color: 'rgba(255, 255, 255, 0.7)',
              transform: 'translate(14px, -6px) scale(0.75)',
              '&[data-shrink="false"]': {
                transform: 'translate(14px, 16px) scale(1)',
              },
            },
          }}
        />
        <Box
          sx={{
            height: '56px',
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <Button
            onClick={handleGenerate}
            variant="outlined"
            sx={{
              height: '100%',
              minWidth: '110px',
              backgroundColor: 'rgba(255, 255, 255, 0.00)',
              color: 'rgba(255, 255, 255, 0.7)',
              borderColor: 'rgba(255, 255, 255, 0.23)',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.12)',
                borderColor: '#ffffff',
              },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            GENERATE
          </Button>
        </Box>
      </Box>

      <ErrorBox 
        open={showError}
        message={errorMessage}
        onClose={() => setShowError(false)}
      />

      <PopupForm
        isVisible={isPopupVisible}
        onClose={() => setIsPopupVisible(false)}
        imageData={highlightData.imageData}
        isFacecam={highlightData.isFacecam}
        onFacecamToggle={handleFacecamToggle}
        onFaceBoxChange={handleFaceBoxChange}
        onGameplayBoxChange={handleGameplayBoxChange}
        handleSubmit={handleSubmit}
        initialTextColor={highlightData.textColor}
        initialCensorSubtitles={highlightData.censorSubtitles}
        initialFontName={highlightData.fontName}
        faceBox={highlightData.faceBox}
        gameplayBox={highlightData.gameplayBox}
      />
    </ThemeProvider>
  );
};

export default HighlightForm;