import React from 'react';
import Link from 'next/link';

const Footer: React.FC = () => {
  return (
    <footer className="w-full pt-2 pb-4 bg-transparent">
      <div className="container mx-auto px-4 text-center">
        <p className="text-gray-400 italic text-sm">
          Made with ❤️ by{' '}
          <Link href="https://x.com/AayushFromSpace" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300 transition-colors duration-300">
            @Aayush
          </Link>
          {''},{' '}
          <Link href="https://x.com/akashinjune" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300 transition-colors duration-300">
            @Akash
          </Link>
          {' '},{' '}
          <Link href="https://x.com/ericschen" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300 transition-colors duration-300">
            @Eric
          </Link>
          ,{' '}and{' '}
          <Link href="https://x.com/zorphfn_" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300 transition-colors duration-300">
            @Tariq
          </Link>
          {' '}--{' '}
          <Link href="/terms" className="text-gray-400 hover:text-white">
            Terms of Service
          </Link>
          {' '}+{' '}
          <Link href="/privacy" className="text-gray-400 hover:text-white">
            Privacy Policy
          </Link>
        </p>
      </div>
    </footer>
  );
  
};

export default Footer;